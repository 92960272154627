import { withRouter, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect,useRef } from "react";
//import PhoneInput from "react-phone-input-2";
import PhoneInput from "react-phone-input-2";
import { flushCart } from "../redux/cartSlice";
import { auth, callFunction, firestore, messaging,app  } from "../provider/firebase";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import {  doc,getDoc } from "firebase/firestore"; 
import axios from "axios";
import moment from 'moment-timezone';
import { calculateOrderPrice, exportLogo, getHostName, inputAlert, parsePrice, somethingWentWrong, successMessage } from "../provider/functions";
import { getToken,isSupported,getMessaging } from "firebase/messaging";
import MySelectBox from "../components/MySelectBox";
import MyTimePicker,{MyTimePicker2} from "../components/MyTimePicker";
import Swal from "sweetalert2";
export default withRouter(PayWebstore);
function PayWebstore({ history }) {
  const dispatch = useDispatch();
  let match = useRouteMatch();
  //const [step, setstep] = useState(1);
  const [loading, setloading] = useState(false);
  const [selfservice, setselfservice] = useState(false);
  const [adsoyad, setadsoyad] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [user, setuser] = useState(false);
  const [il, setil] = useState("");
  const [ilce, setilce] = useState("");
  const [mahalle, setmahalle] = useState("");
  const [adres, setadres] = useState("");
  const [odemeyontemi, setodemeyontemi] = useState("");
  const [deactiveTimes, setdeactiveTimes] = useState([]);
  const [kvkk, setkvkk] = useState(false);
  const [bilgipaylasim, setbilgipaylasim] = useState(false);
  const [bildirim, setbildirim] = useState("");
  const language = useSelector((state) => state.language.data);
  const selectedLang = useSelector((state) => state.language.selected);
  const serverReducer = useSelector((state) => state.server.data);
  const categories = useSelector((state) => state.categories.data);
  const currency = serverReducer.currency;
  const cart = useSelector((state) => state.cart.data);
  const toplam = parseFloat( parseFloat( cart.map(v=>calculateOrderPrice(v)).reduce((a, b) => a + b, 0)).toFixed(2))
  const {freekargo,kargo,min,opened,payments,deliveryType,limits,closed} = serverReducer.webstore || {open:false,min:0,kargo:0,freekargo:0,payments:[],deliveryType:[],limits:{},closed:"00:00"}
  const kargoFiyati = parsePrice( !selfservice ? (toplam>freekargo ? 0 : kargo) : 0);
  const [lat, setLat] = useState(0);
  const [teslimattype, setteslimattype] = useState("");
  const [teslimzamanlari, setteslimzamanlari] = useState([]);
  const [teslim, setteslim] = useState("now");
  const [lng, setLng] = useState(0);
  const options = [{label:language.odeme_yontemi_online_odeme,value:"paytr"},...payments.map(v=>({label:v,value:v}))].filter(v=>v.value!="paytr" || (v.value=="paytr" && serverReducer.paytr));
  async function onSignInSubmit(){
    if(!selfservice && (teslimattype=="" || adsoyad.length<3 || ilce.length<3 || mahalle.length<3 || adres.length<3)){
      return somethingWentWrong( language.hata,language.bilgileri_doldurun)
    }
    setloading(true);
    const access_token = await  auth.currentUser?.getIdToken();
    callFunction("paketGonder")({
      access_token,
      server: match.params.server ? match.params.server : getHostName(),
      cart,
      scheduledDate:teslim,
      name: adsoyad,
      selfservice,
      latitude:lat,
      longitude:lng,
      phone:phoneNumber,
      address:adres,
      region:mahalle,
      district:ilce,
      notification:bildirim,
      county:il,
      paymentNote:odemeyontemi,
      paymentMethod:odemeyontemi
    }).then((res)=>{
      if (res.data.error) {
        if(res.data.error=="recaptcha-error"){
          return Swal.fire("Recaptcha!", language.recaptcha, "error");
        }if (res.data.error == "server-not-found") {
          return Swal.fire("Hata!", language.restorant_not_found, "error");
        }else if (res.data.error == "cart-not-found") {
          history.goBack();
          return Swal.fire("Hata!", language.sepette_urun_yok, "error");
        }else if (res.data.error == "name-not-found") {
          return Swal.fire("Hata!", language.ad_zorunlu, "error");
        }else if (res.data.error == "table-not-found") {
          return Swal.fire("Hata!", language.masa_zorunlu, "error");
        }else if (res.data.error == "webstoreclosed") {
          return somethingWentWrong("Hata!", language.webstore_kapali_baslik, "error");
        }else if (res.data.error == "minprice") {
          return somethingWentWrong("Hata!", language.minimum_tutar_altinda_baslik, "error");
        }else if (res.data.error == "paymentmethod") {
          return somethingWentWrong("Hata!", language.odeme_yontemi_zorunlu, "error");
        }
      }
      if (res.data.success == true) {
        if(res.data.url){
          window.location=res.data.url;
          return;
        }
        history.replace((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/welcome")
        dispatch(flushCart());
        return successMessage(
          language.basarili,
          language.siparis_iletildi,
          "success"
        );
      }
      return somethingWentWrong(
       language.hata,
        language.birseyler_ters_gidiyor,
        "error"
      );
    })
    .finally(()=>setloading(false))
  }
 
  useEffect( ()=>{
    [...cart].length==0 &&  history.replace((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/welcome")

    const subscribe = auth.onAuthStateChanged(userData=>{
      if(userData) {
       getDoc( doc(firestore,"users/"+userData.uid)).then(snapshot=>{
         if(snapshot.exists){
           !String(snapshot.data()?.address).toLowerCase().includes("self") && setadres(snapshot.data()?.address || "")
           !String(snapshot.data()?.county).toLowerCase().includes("self") && setil(snapshot.data()?.county || "")
           !String(snapshot.data()?.district).toLowerCase().includes("self") && setilce(snapshot.data()?.district || "")
           !String(snapshot.data()?.region).toLowerCase().includes("self") && setmahalle(snapshot.data()?.region || "")
         }
       })
        setuser(userData)
        setphoneNumber(userData.phoneNumber)
        setadsoyad(userData.displayName || "");
      }
    })
    if(options.length>0) setodemeyontemi(options[0].value)
    return ()=>subscribe();
  },[])
  useEffect(()=>{
    if([...(deliveryType ?? [])].length==0){
      setteslimattype("home")
    }
  },(deliveryType ?? []))

  useEffect(()=>{
    var teslimZamanlari = [{label:language.siparis_teslimati_en_kisa_zamanda,value:"now"}];
    const saat = moment.tz(new Date(),serverReducer.timezone).add(1,"hour").minute(0);
    const suan = moment.tz(new Date(),serverReducer.timezone);
    const acilis = moment.tz(moment(opened).year(suan.year()).month(suan.month()).date(suan.date()),serverReducer.timezone)
    const kapanis = moment.tz(moment(closed).year(suan.year()).month(suan.month()).date(suan.date()),serverReducer.timezone);
    for (let index =0; index < 25; index++) {
      const add = saat.add(15,"minute");
     if(add.isBetween(acilis,kapanis))
      teslimZamanlari.push({label:add.format("HH:mm"),value:add.valueOf()})
    }
    if(limits && Object.keys(limits).length==0){
      setteslimzamanlari(teslimZamanlari)
      return Promise.resolve();
    }
    async function main() {
      const access_token = await  auth.currentUser?.getIdToken();
      callFunction("CheckLimits")({
        access_token,
        server: match.params.server ? match.params.server : getHostName(),
        cart
      }).then(response=>{
        const deactiveTimes = (response.data.deactiveTimes);
        const deactiveTime = deactiveTimes.map(v=>v.time);
 
        setdeactiveTimes(deactiveTimes.map(v=>({...v,...categories.find(val=>val.id==v.cat)})))
        setteslimzamanlari(teslimZamanlari.map(value=>({...value,isDisabled:deactiveTime.includes(moment.tz(value.value,serverReducer.timezone).format("HH:mm"))})))
      }).catch(()=> setteslimzamanlari(teslimZamanlari))
    }
    main();
  },[limits])
  return (
    <div>
      <div className="rp-app rp-search">
        <div className="container-fluid">
          <div className="rp-page-body " style={{ marginBottom: 100 }}>
            <div className="rp-page-header" style={{backgroundImage:"linear-gradient(205deg, "+serverReducer.bgcolor2+", "+serverReducer.bgcolor1+")"}}>
              <div className="rp-hh-content flex-between">
                <div className="area">
                  <a onClick={history.goBack} className="link">
                    <i className="fa fa-arrow-left" />
                  </a>
                </div>
                <div className="area">
                  <div className="logo">
                    <img
                      src={
                        serverReducer.image
                          ? serverReducer.image
                          : "../"+exportLogo(serverReducer.host)
                      }
                      alt="Logo"
                    />
                  </div>
                </div>
                <div className="area" style={{minWidth:40}}>
                  {/* <a className="link">
                    <i className="fa fa-bell" />
                  </a> */}
                </div>
              </div>
            </div>

          
              <h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>{language.ad}</h2>
              <div className="mb-4">
                <input
                  className={"form-control rp-input-text"+(adsoyad.length<3 ?" is-invalid":"")}
                  placeholder="Joe Doe"
                  value={adsoyad}
                  onChange={(e) => setadsoyad(e.target.value)}
                ></input>
              </div>
              <h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>{language.teslimat}</h2>
                <div className="mb-4">
                <select className={"form-control" +(teslimattype=="" ?" is-invalid":"")} onChange={(e)=>{
                    if(e.target.value=="table"){setselfservice(true); setadres(language.masaya_siparis)}
                    if(e.target.value=="packet"){setselfservice(true); setadres(language.paket_siparis)}
                    if(e.target.value=="home"){setselfservice(false);}
                    setteslimattype(e.target.value)
                }}>
                  <option>{language.sec}</option>
                  {(deliveryType ?? []).includes("table") && <option value="table" selected={teslimattype=="table"}>{language.masaya_siparis}</option>}
                  {(deliveryType ?? []).includes("packet") && <option value="packet" selected={teslimattype=="packet"}>{language.paket_siparis}</option>}
                  <option value="home" selected={teslimattype=="home"}>{language.adrese_gelsin}</option>
                </select>
                </div>
             
             {!selfservice && <>
                <h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>{language.ilce}</h2>
                <div className="mb-4">
                <input
                    className={"form-control rp-input-text"+(ilce.length<2 ?" is-invalid":"")}
                    placeholder=""
                    value={ilce}
                    onChange={(e) => setilce(e.target.value)}
                  ></input>
                </div>
                <h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>{language.mahalle}</h2>
                <div className="mb-4">
                <input
                    className={"form-control rp-input-text"+(mahalle.length<3 ?" is-invalid":"")}
                    placeholder=""
                    value={mahalle}
                    onChange={(e) => setmahalle(e.target.value)}
                  ></input>
                </div>
                <h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>{language.adres}</h2>
                <div className="mb-4">
                  <textarea
                    rows={3}
                    className={"form-control rp-input-text"+(adres.length<3 ?" is-invalid":"")}
                    placeholder=""
                    value={adres}
                    onChange={(e) => setadres(e.target.value)}
                  ></textarea>
                </div>
              </>}
                <h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>{language.siparis_teslimati}</h2>
                <div className="mb-4">
                <MySelectBox options={teslimzamanlari}  
                onChange={(e)=>(e && e.value) && setteslim(e.value)}
                isSearchable={false} 
                value={teslimzamanlari.find(v=>v.value==teslim)} 
                  />

                {deactiveTimes.map(v=><span className="badge bg-danger" style={{textWrap:'auto',marginTop:10}}>{v.time} itibarıyla yoğunluk nedeniyle {v.title} siparişlerinde gecikme yaşanmaktadır. {v.time}'de yeni {v.title} siparişi alamıyoruz. Anlayışınız için teşekkür ederiz</span>)}
                {/* <MyTimePicker2
                ampm={false}
                renderInput={(params) => <TextField {...params} />}
                /> */}
                </div>
              <h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>{language.odeme_yontemi}</h2>
              
              <div className="btn-group rp-checkout-buttons" >
               {options.map((v,k)=><a className="btn btn-outline-dark" style={{border:"0.1px solid black",backgroundColor:odemeyontemi==v.value?'orange':'white',color:odemeyontemi==v.value?'white':'black'}}key={k} onClick={()=>setodemeyontemi(v.value)}><div className="img"><img src={"../../assets/img/"+(v.value=="paytr"?"mastercard.png":"cash-payment.png")} /></div> {v.label}</a>)}
              </div>

              {/* {bildirim.length<3 &&<button className="btn  btn-block" style={{backgroundColor:'black',color:'white',marginTop:20,marginBottom:20}} onClick={e=>{e.preventDefault();requestPushNotification()}}>
                 Sipariş Durumlarını Bildirim Olarak Al
              </button>} */}
             
            
          
            
            <div className="rp-action-margin-cart" />
            <div style={{height:100}}/>
          </div>
        </div>
      </div>

      <div className="rp-action rp-action-cart">
      <center style={{ paddingBottom: 20,display:'block' }}> 
       <div id="recaptcha-container" className="recaptcha-container"/>
            </center>
        {(kargoFiyati>0) &&<div className="price-info" >
          <span className="text"> <small className="text-muted">{language.ayarlar_kuver}</small></span>
          <span className="price"><small className="text-muted">+{kargoFiyati} {currency}</small></span>
        </div>}
        <div className="price-info" style={{marginBottom:0}}>
          <span className="text">{language.toplam}</span>
          <span className="price">{toplam+kargoFiyati} {currency}</span>
        </div>
        <button
          className="btn btn-dark btn-block"
          onClick={onSignInSubmit}
          style={{color:"white"}}
          disabled={ loading || cart.length==0}
        >
          {loading ? (
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            `${language.siparis_ver} ${toplam+kargoFiyati} ${currency}`
          )}
        </button>
      </div>
    </div>
  );
}
