import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Redirect,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useHistory,
} from "react-router-dom";
import MenuPage from "./pages/Menu";
import { useSelector } from "react-redux";
import SplashScreen from "./pages/SplashScreen";
import SearchPage from "./pages/Search";
import ProductPage from "./pages/Product";
import CartPage from "./pages/Cart";
import LoginPage from "./pages/Login";
import CategoryPage from "./pages/Category";
import ReactGA from 'react-ga';
import _ from 'lodash';
import {
  ServerLoadError,
  ServerLoadSuccess,
  ProductLoadSuccess,
  CategoriesLoadSuccess,
  PaymentsLoadSuccess,
  TablesLoadSuccess,
  changeLanguage,
  MostSellerLoadSuccess,
} from "./redux";
import axios from "axios";
import store from "./redux/store";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import WelcomePage from "./pages/Welcome";
import PayCart from "./pages/PayCart";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PayWebstore from "./pages/PayWebstore";
import { getHostName, getOrDefault, isLocal, yuzdeIslemleri } from "./provider/functions";
import GarsonCagir from "./pages/GarsonCagir";
import Degerlendirme from "./pages/Degerlendirme";
import VerifyPhone from "./pages/VerifyPhone";
import ProfilePage from "./pages/Profile";
import PayTable from "./pages/PayTable";
import Paytr from "./pages/Paytr";

export default function App() {
  if(isLocal()){
    return (
      <>
        <ToastContainer limit={1} />
        <Router>
          <Switch>
            <Route path="/:server/:table">
              <InsideServer />
            </Route>
            <Route path="/:server" render={(prop) => <Redirect to={`/${prop.match.params.server}/undefined`} />} />
            <Route exact path="/" ><section className="page_404">
                  <div className="container">
                    <div className="row">
                      <div className="col-sm-12 ">
                        <div className="col-sm-10 col-sm-offset-1  text-center">
                          <div className="four_zero_four_bg">
                            <h1 className="text-center ">404</h1>
                          </div>
                          <div className="contant_box_404">
                            <h3 className="h2">Look like you're lost</h3>
                            <p>the page you are looking for not avaible!</p>
                          
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
          </Route>
            <Redirect to="/demo/undefined" />
          </Switch>
        </Router>
      </>
    );
  }else{
    return (
      <>
        <ToastContainer limit={1}/>
        <Router>
          <Switch>
            <Route path="/:table">
              <InsideServer />
            </Route>
            <Redirect to="/webstore" />
          </Switch>
        </Router>
      </>
    );
  }
}
function convertRgba(value){
  if(typeof value == "object" && value && "r" in value && "g" in value && "b" in value && "a" in value){
    return `rgba(${value.r},${value.g},${value.b},${value.a})`
  }
  return value;
}
function fixedAllRgba(obj){
  var temiz = {}
  Object.keys(obj).forEach(key=>{
    temiz[key]=convertRgba(obj[key]);
  })
  return temiz;
}
function InsideServer() {
  let match = useRouteMatch();
  let history = useHistory();
  const rlink =  window.location.hostname.includes("localhost")?"http://127.0.0.1:5001/restomenum3/us-central1/qr?url=": "https://us-central1-restomenum3.cloudfunctions.net/qr?url=";
  let link = rlink + (isLocal()? match.params.server: getHostName());
  if(window.location.hostname.includes("tarihibeylerbeyitatlicisi")){
    link = rlink + "tarihibeylerbeyitatlicisi";
  }
  useEffect(() => {
    if(match.params.server=="alismutfak" && match.params.table=="undefined"){
      history.replace("/alismutfak/webstore")
    }else  if(getHostName()=="obarbeque" && match.params.table=="menu"){
      history.replace("/webstore/welcome")
    }else  if(match.params.server=="monocafe" && match.params.table=="webstore"){
      window.location = "https://menu-online.co/monocafe/undefined"
    }else  if(getHostName().includes("xn--dolaskcafe-1ub") && match.params.table=="webstore"){
      window.location = "https://xn--dolaskcafe-1ub.com/undefined/welcome"
    }else if(window.location.hostname.includes("tarihibeylerbeyitatlicisi") && match.params.table=="webstore"){
      window.location = "https://menu.tarihibeylerbeyitatlicisi.com.tr/undefined/welcome"
    } else if(match.params.server=="httpsgurmenucomtrrestaurantgeveze"){
      window.location = "https://menu-online.co/gevezemeyhane"
    }if(match.params.server=="assawaffle" && match.params.table=="webstore"){
      window.location = "https://menu-online.co/assawaffle"
    }if(match.params.server=="wwwlotuscafecom" && match.params.table=="webstore"){
      window.location = "https://menu-online.co/wwwlotuscafecom"
    }
  
    const language = store.getState().language.data;
   
    window.grecaptcha.enterprise.ready(()=>{
      window.grecaptcha.enterprise.execute('6Lc7XZMqAAAAAOxV4_eE7mic2A4fDg_BxowGYlzR', {action: 'qrMenu'}).then((token)=>{
        axios
            .get(link+"&token="+token)
            .then((response) => {
              if (response.status == 200) {
                if(response.data.error){
                  return Swal.fire(
                    language.hata,
                    language.ayarlar_subsribe_ended,
                    "error"
                  );
                }
                const { categories, payments, products, server,tables,webstore,paytr,mostSeller,defaultLanguage } = response.data;
                const productsLanguages= (_.uniq(products.filter(v=>v.languages).map(v=>Object.keys(v.languages)).reduce((a,b)=>a.concat(b),[])).filter(v=>v.length==2))
                const languages = _.uniq([...(response.data.languages ?? []),...productsLanguages]);
                const selectedLang = getOrDefault("language","none");
                var myDynamicManifest = {
                  "name": server.title,
                  "short_name": server.title,
                  "description": "",
                  "background_color": "#000000",
                  "theme_color": "#0f4a73",
                }
                const stringManifest = JSON.stringify(myDynamicManifest);
                const blob = new Blob([stringManifest], {type: 'application/json'});
                const manifestURL = URL.createObjectURL(blob);
                document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);
                if(selectedLang=="none"){
                  let change = false;
                  const dil = (window.navigator.language).split("-")
                  for (let index = 0; index < dil.length; index++) {
                    const element = dil[index];

                    if(languages.includes(element.toUpperCase())){
                      change=true;
                      store.dispatch(changeLanguage(element))
                    }
                  }
                  if(!change){
                     store.dispatch(changeLanguage(defaultLanguage))
                  }
                }else{ 
                  if(selectedLang=="SA"){
                    store.dispatch(changeLanguage("AR"))
                  }else if(selectedLang.toUpperCase().includes(languages)) {
                     store.dispatch(changeLanguage(selectedLang))
                  }else  store.dispatch(changeLanguage(defaultLanguage))
                 }
                if(server?.googleanalytics){
                  ReactGA.initialize(server?.googleanalytics);
                  ReactGA.pageview(window.location.pathname + window.location.search);
                }
                if(server?.title){
                  document.title = server?.title
                }
                const isWebStore = match.params.table=="webstore";
                const cids = categories.map(v=>v.id)
                var myProducts = products.filter(v=>!v.stokluSatis || (v.stokluSatis && v.stok>0)).filter(p=>cids.includes(p.category))
              
                .filter(v=>(!isWebStore && !v.qrHide) || (isWebStore && !v.webstoreHide) )
                .sort((a,b)=>a.rank-b.rank)
     
                if(isWebStore){
                  myProducts = myProducts.map(value=>{
                    return {...value,price:value.webStorePrice ? value.webStorePrice: value.price}
                  })
                }
                if(isWebStore && webstore.zam && !isNaN(parseInt(webstore.zam))){
                  myProducts=myProducts.map(value=>Object.assign(value,{price:yuzdeIslemleri.kdvekle(value.price,webstore.zam)}))
                }
                const MostSeller = [...(mostSeller ?? [])].map(value=>({
                  rank:value.rank,
                  ...myProducts.find(product=>product.id==value.id)
                })).filter(v=>v.title);
                if(server.bgImage){
                   document.body.style.background=`url(${server.bgImage}) no-repeat center center fixed`;
                   document.body.style.backgroundSize=`cover`;
                }else if(server.backgroundColor){
                  document.body.style.backgroundColor=fixedAllRgba(server).backgroundColor;
                }
                const {open,closed,opened} = webstore || {open:false,min:0,kargo:0,freekargo:0,closed:0,opened:0}
                const saat = parseInt(moment.tz(moment(),server.timezone || "Europe/Istanbul").format("HHmm"))
                const closedSaat = parseInt(moment.tz(moment(closed),server.timezone || "Europe/Istanbul").format("HHmm"))
                const openedSaat = parseInt(moment.tz(moment(opened),server.timezone || "Europe/Istanbul").format("HHmm"))
                const webstoreIsClosed = !open || (saat>closedSaat && !(closedSaat<900)) || (saat<openedSaat && !(openedSaat>900)) ;
                // document.body.style.backgroundColor="black"
               
                store.dispatch(ProductLoadSuccess(myProducts));
                store.dispatch(MostSellerLoadSuccess(MostSeller));
                store.dispatch(CategoriesLoadSuccess(categories.filter(v=>(!isWebStore && !v.qrHide) || (isWebStore && !v.webstoreHide) ).sort((a,b)=>a.rank-b.rank)));
                store.dispatch(PaymentsLoadSuccess(payments));
                setTimeout(() => {
                  store.dispatch(ServerLoadSuccess({...fixedAllRgba(server),languages,webstore:{...webstore,isClosed:webstoreIsClosed},paytr}));
                }, 500);
                store.dispatch(TablesLoadSuccess(tables));
              
                setTimeout(() => {
                  const language = store.getState().language.data;
                  if(match.params.table=="webstore" && webstoreIsClosed){
                     Swal.fire(language.webstore_kapali_baslik,language.webstore_kapali_aciklama,"error")
                  }else if(server.qrSiparis)
                  {
                    // toast.success(language.siparis_acik_title+": "+language.siparis_acik_desc,{position:'bottom-right'})
                  }
                }, 1000);
              
                return "ok";
              }
             
              Swal.fire(
                language.hata,
                language.birseyler_ters_gidiyor+"-",
                "error"
              );
              //store.dispatch(ServerLoadError("Bağlantı Kurulamadı!"));
            }).catch((err) => {
            
              Swal.fire(
                language.hata,
                language.birseyler_ters_gidiyor,
                "error"
              );
            });
        }, []);
      });
    },[])
    
  const loading = useSelector((state) => state.server.loading);
  if (loading) {
    return <SplashScreen />;
  }
  return (
    <>
      <Switch>
        <Route path={`${match.path}/welcome`}>
          <WelcomePage />
        </Route>

        <Route path={`${match.path}/menu`}>
          <MenuPage />
        </Route>
        <Route path={`${match.path}/categories/`}>
          <CategoryPage />
        </Route>
        <Route path={`${match.path}/product/:id`}>
          <ProductPage />
        </Route>
        <Route path={`${match.path}/search`}>
          <SearchPage />
        </Route>
        <Route path={`${match.path}/cart`}>
          <CartPage />
        </Route>
        <Route path={`${match.path}/garsoncagir`}>
          <GarsonCagir />
        </Route>
        <Route path={`${match.path}/paycart`}>
          <PayCart />
        </Route>
        <Route path={`${match.path}/degerlendir`}>
          <Degerlendirme />
        </Route>
        <Route path={`${match.path}/paywebstore`}>
          <PayWebstore />
        </Route>
        <Route path={`${match.path}/paytable`}>
          <PayTable />
        </Route>
        <Route path={`${match.path}/paytr`}>
          <Paytr />
        </Route>
        <Route path={`${match.path}/login`}>
          <LoginPage />
        </Route>
        <Route path={`${match.path}/verify`}>
          <VerifyPhone />
        </Route>
        <Route path={`${match.path}/profile`}>
          <ProfilePage />
        </Route>
        <Redirect to={`${match.path}/welcome`} />
      </Switch>
    </>
  );
}
