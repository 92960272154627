import { Link,useLocation,useRouteMatch } from "react-router-dom";
import { useSelector } from "react-redux";
import { calculateOrderPrice, parsePrice } from "../provider/functions";
export default function BottomNavigation(){
  const location = useLocation();
  let match = useRouteMatch();
  const isWebStore = match.params.table=="webstore";
  const serverReducer = useSelector((state) => state.server.data);
  const {currency,qrSiparis,bgcolor1,buttonColor} = serverReducer;
  const textColor="white"
  const language = useSelector((state) => state.language.data);
  const cart = useSelector(state => state.cart.data)
  const toplam = parsePrice( parseFloat( cart.map(v=>calculateOrderPrice(v)).reduce((a, b) => a + b, 0)).toFixed(2))

  const {freekargo,kargo,min,open} = serverReducer.webstore || {open:false,min:0,kargo:0,freekargo:0}
  const kargoFiyati = parsePrice(isWebStore ? toplam>freekargo ? 0 : kargo : 0);
  const BottomBars = ()=>  <div className="rm-bottomnav" style={{backgroundColor:serverReducer.backgroundColor}}>
  <div className="row">
    <div className="col">
      <Link to={(match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/menu"} className={String(location.pathname).endsWith("menu") ? "link active":"link"}>
        <div className="icon">
          <i className="fa fa-bars"   style={{color:serverReducer.textColor}} />
        </div>
        {/* <div className="name">Home</div> */}
      </Link>
    </div>
    <div className="col">
      <Link to={(match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/search"} className={String(location.pathname).endsWith("search") ? "link active":"link"}>
        <div className="icon">
          <i className="fa fa-search"   style={{color:serverReducer.textColor}} />
        </div>
        {/* <div className="name">Search</div> */}
      </Link>
    </div>
   {(qrSiparis || match.params.table=="webstore")? <div className="col">
      <Link to={(match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/cart"} style={{position:'relative'}} className={String(location.pathname).endsWith("cart") ? "link active":"link"}>
        <div className="icon">
          <i className="fa fa-shopping-cart"  style={{color:serverReducer.textColor}}/>
        
        </div>
      {cart.length>0 && <span className="position-absolute  badge rounded-pill bg-danger" style={{right:0,top:0}}> {cart.length}</span>}
        {/* <div className="name">Search</div> */}
      </Link>
    </div>:""}
    <div className="col">
      <Link to={(match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/degerlendir"} className={String(location.pathname).endsWith("degerlendir") ? "link acti</div>ve":"link"}>
        <div className="icon">
          <i className="fa fa-comment" style={{color:serverReducer.textColor}}  />
        </div>
        {/* <div className="name">Search</div> */}
      </Link>
    </div>
  </div>
</div>
  if([...cart].length==0 || (!qrSiparis && match.params.table!="webstore")){
    return <BottomBars/>;
  }
  return (<>
  <div className="rm-bottom-cart">
  <Link className="btn btn-dark"  to={`${(match.params.server?"/"+match.params.server:"")}/${match.params.table}/cart`}>
    <span className="number" style={{color:textColor}}> {[...cart].length}</span>
    <span className="name" style={{color:textColor}}>{language.sepete_git}</span>
    <span className="price" style={{color:textColor}}>{toplam>0?`${toplam} ${currency}`:""}</span>
  </Link>
  <BottomBars/>
</div>
  </>
)
  return (
    <div className="rm-bottomnav">
          <div className="row">
            <div className="col">
              <Link to={`${(match.params.server?"/"+match.params.server:"")}/${match.params.table}/`} className="link">
                <i className="fa fa-home" />
              </Link>
            </div>
            {/* <div className="col">
              <a  className="link">
                <i className="fa fa-boxes" />
              </a>
            </div> */}
            <div className="col">
              <Link to={`${(match.params.server?"/"+match.params.server:"")}/${match.params.table}/menu`} className={qrSiparis? "link mid":'link'}>
                <i className="fa fa-qrcode" />
              </Link>
            </div>
            {/* <div className="col">
              <a  className="link">
                <i className="fa fa-pen" />
              </a>
            </div> */}
          {(qrSiparis || match.params.table=="webstore") && <div className="col">
              <Link to={`${(match.params.server?"/"+match.params.server:"")}/${match.params.table}/cart`} className="link">
                <i className="fa fa-shopping-cart" />
              </Link>
            </div>}
          </div>
        </div>
  )

}