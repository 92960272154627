import { withRouter, useRouteMatch,useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import { auth, callFunction } from "../provider/firebase";
import { signInWithPhoneNumber, RecaptchaVerifier,signInWithCustomToken } from "firebase/auth";
import { somethingWentWrong } from "../provider/functions";
import { createRef } from "react";
export default withRouter(PayCart);
function PayCart({ history }) {
  const dispatch = useDispatch();
  let match = useRouteMatch();
  const isWebStore = match.params.table=="webstore" || match.params.table=="online";
  //const [step, setstep] = useState(1);
  const language = useSelector((state) => state.language.data);
  const [loading, setloading] = useState(false);
  const {state} = useLocation();
 
  const [onaykodu, setonaykodu] = useState("");
  //const [verifycode, setverifycode] = useState("");
  //const [sharenumber, setsharenumber] = useState(false);
  // const [adres, setadress] = useState("");
  //const [coords, setcoords] = useState(null);
  const serverReducer = useSelector((state) => state.server.data);
  const tables = useSelector((state) => state.tables.data);
  const cart = useSelector((state) => state.cart.data);
  const _inputref = createRef();
  

  useEffect(() => {
    console.log(state)
    if(!window.confirmationResult && !state){
      history.goBack();
      return ;
    }
    const subscribe = auth.onAuthStateChanged((userData) => {
      if (userData) {
        if(isWebStore){
          history.replace((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/paywebstore")
        }else if(serverReducer.auth && match.params.table!="undefined"){
          history.replace((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/paytable")
        }
      }
    });
    return ()=>subscribe();
  }, []);
  useEffect(()=>{
    _inputref.current?.focus();
  },[_inputref.current])
  function afterLogin(res){
    setloading(false);
    if(res.user){
        if(isWebStore){
          history.replace((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/paywebstore")
        }else if(serverReducer.auth && match.params.table!="undefined"){
          history.replace((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/paytable")
        }else {
          history.replace((match.params.server?"/"+match.params.server:"")+"/webstore/paywebstore")
        }
    }else{
      alert("Login failed!")
    }
  }
  function gonder() {
    setloading(true);
    if(state?.provider=="twilio"){
      return callFunction("verifyPhone")({...state,code:onaykodu}).then((response)=>{
        if(response?.data?.data){
          signInWithCustomToken(auth,response.data.data).then(afterLogin)
        }
      }).finally(()=>{
        setloading(false);
      })
    }
    window.confirmationResult?.confirm(onaykodu).then((res)=>{
      afterLogin(res)
    
    }).catch((err)=>{
      console.log(err,onaykodu)
      alert(err.code)
      setloading(false)
    })
  }
  return (
    <div>
      <div className="rp-app rp-search">
        <div className="container-fluid">
          <div className="rp-page-head rp-head-shop">
            <a
              onClick={() => history.goBack()}
              className="back"
              style={{ zIndex: 999 }}
            >
              <i className="fa fa-arrow-left" />
            </a>
            <h1 className="title title-cart">
              {language.login_sayfasi_baslik}{" "}
              <span className="shop-name">{serverReducer.title}</span>
            </h1>
          </div>
          <div className="rp-cart-steps active3">
            <div className="step">
              <span className="number">1</span>
              <span className="name" style={{ color: serverReducer.textColor }}>
                {language.menu}
              </span>
            </div>
            <div className="step">
              <span className="number">2</span>
              <span className="name" style={{ color: serverReducer.textColor }}>
                {language.sepet}
              </span>
            </div>
            <div className="step">
              <span className="number">3</span>
              <span className="name" style={{ color: serverReducer.textColor }}>
                {language.login_sayfasi_baslik}
              </span>
            </div>

            <div className="step">
              <span className="number">4</span>
              <span className="name" style={{ color: serverReducer.textColor }}>
                {language.odeme}
              </span>
            </div>
          </div>
          <div className="page-auth">
            <div className="rp-auth-head">
              {/* <h2 className="title">{language.login_sayfasi_baslikaciklama}</h2> */}
              <span className="text">{language.telefon_onay_aciklama}</span>
            </div>

            <div className="group rp-verification-inputs">
             
              
                  <input type="text" className="form-control" ref={_inputref} value={onaykodu} 
                  placeholder="Verify Code"
                  onChange={(e)=>{
                  
                    setonaykodu(e.target.value)
                  }}/>
               
                
           
              {/* <div className="text-end">
                <a>Resent Code</a>
              </div> */}
            </div>
          </div>
        </div>
      </div>

      <div className="rp-action rp-action-cart">
        <button
          className="btn btn-dark btn-block"
          style={{
            backgroundColor: serverReducer.buttonColor,
            borderColor: serverReducer.buttonColor,
            color: "white",
          }}
          onClick={gonder}
          disabled={loading}
        >
          {loading ? (
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            language.telefon_onay_gonder
          )}
        </button>
      </div>
    </div>
  );
}
