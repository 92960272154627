import { withRouter, useRouteMatch } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useState, useEffect,useRef } from "react";
import PhoneInput from "react-phone-input-2";
import { auth, callFunction  } from "../provider/firebase";
import { signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { somethingWentWrong } from "../provider/functions";
export default withRouter(PayCart);
function PayCart({ history }) {
  const dispatch = useDispatch();
  let match = useRouteMatch();
  const isWebStore = match.params.table=="webstore" || match.params.table=="online";
  //const [step, setstep] = useState(1);
  const language = useSelector((state) => state.language.data);
  const [loading, setloading] = useState(false);

  const [telefon, settelefon] = useState(language.telkodu ?? "");
  const [kvkk, setkvkk] = useState(false);
  const [bilgipaylasim, setbilgipaylasim] = useState(false);
  //const [verifycode, setverifycode] = useState("");
  //const [sharenumber, setsharenumber] = useState(false);
  // const [adres, setadress] = useState("");
  //const [coords, setcoords] = useState(null);
  const serverReducer = useSelector((state) => state.server.data);
  const tables = useSelector((state) => state.tables.data);
  const cart = useSelector((state) => state.cart.data);

  useEffect(()=>{
    const subscribe = auth.onAuthStateChanged(userData=>{
    
      if(userData) {
        if(isWebStore){
          history.replace((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/paywebstore")
        }else if(serverReducer.auth && match.params.table!="undefined"){
          history.replace((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/paytable")
        }else{
          history.replace((match.params.server?"/"+match.params.server:"")+"/webstore/paywebstore")
        }
      }
    })
   
        window.recaptchaVerifier = new RecaptchaVerifier(auth,'recaptcha-container',{
          'size': 'invisible',
        });
        // window.recaptchaVerifier.render().then((widgetId) => {
        //   window.recaptchaWidgetId = widgetId;
        // });
    return subscribe;
  },[])
  function gonder(){

    if(!bilgipaylasim || !kvkk ){
      return somethingWentWrong( language.hata,language.bilgileri_doldurun)
    }
    setloading(true);
    signInWithPhoneNumber(auth,"+"+telefon,window.recaptchaVerifier).then((confirmationResult)=>{
      setloading(false);
      window.confirmationResult = confirmationResult
      history.replace((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/verify")
    }).catch((error) => {
      setloading(false);
      if (error.code == "auth/invalid-phone-number") {
        return somethingWentWrong(
          language.invalid_phone_number,
          language.invalid_phone_number_text
        );
      }else if(error.code=="auth/too-many-requests"){
        return somethingWentWrong(
          language.hata,
          language.to_many_phone_submit
        );
      }else if(error.code=="auth/error-code:-39"){
        return sendManuelCode();
      }else{console.log(error); alert(error.code)}

    })
  }
  function sendManuelCode(){
    return window.grecaptcha.enterprise.ready(()=>window.grecaptcha.enterprise.execute('6Lc7XZMqAAAAAOxV4_eE7mic2A4fDg_BxowGYlzR', {action: 'qrMenu'}).then((token)=>{
      return callFunction("sendSms")({phone:"+"+telefon,token}).then((response)=>{
        if(response?.data?.success && response?.data?.data){
          history.replace((match.params.server?"/"+match.params.server:"")+"/"+match.params.table+"/verify",{phone:"+"+telefon,provider:"twilio",token:response?.data?.data})
        }
      })
    }))
  }
  return (
    <div>
      <div className="rp-app rp-search">
        <div className="container-fluid">
        <div className="rp-page-head rp-head-shop">
            <a onClick={()=>history.goBack()} className="back" style={{zIndex:999}}><i className="fa fa-arrow-left" /></a>
            <h1 className="title title-cart">{language.login_sayfasi_baslik} <span className="shop-name">{serverReducer.title}</span></h1>
          </div>
          <div className="rp-cart-steps active3"> 
            <div className="step">
              <span className="number" >1</span>
              <span className="name"  style={{color:serverReducer.textColor}}>{language.menu}</span>
            </div>
            <div className="step">
              <span className="number">2</span>
              <span className="name"  style={{color:serverReducer.textColor}}>{language.sepet}</span>
            </div>
            <div className="step">
              <span className="number">3</span>
              <span className="name"  style={{color:serverReducer.textColor}}>{language.login_sayfasi_baslik}</span>
            </div>
           
            <div className="step">
              <span className="number">4</span>
              <span className="name"  style={{color:serverReducer.textColor}}>{language.odeme}</span>
            </div>
           
          </div>
        <div className="page-auth">
      <div className="rp-auth-head">
        {/* <h2 className="title">{language.login_sayfasi_baslikaciklama}</h2> */}
        <span className="text">{language.telefon_onay_aciklama}</span>
      </div>

      <div className="group">
        {/* <label htmlFor="input1" className="label-inline">{language.telefon_input_placeholder}</label> */}
        <PhoneInput  placeholder={"+"+language.telkodu}  specialLabel="" value={telefon} onChange={settelefon}
                        inputClass="form-control" />
        {/* <input type="email" className="form-control" id="input1" placeholder={"+"+language.telkodu} /> */}
      </div>
      <div style={{height:20}} />
      <div id="recaptcha-container" className="recaptcha-container" />
    <h2 className="rp-page-name-mini" style={{color:serverReducer.textColor}}>{language.kullanim_kosullari}</h2>
              <div className="mb-2">
                  <a className="d-flex align-items-center mb-3 form-control"   onClick={()=>setkvkk(kvkk==false)}>
                    <div style={{width: '40px', height: '25px'}}>
                    <input type="checkbox" style={{
                      width: 25,
                      height: 25,
                      marginRight: 15,
                      marginBottom: 2,
                    }}  checked={kvkk} onClick={() => setkvkk(kvkk==false)} />
                    </div>
                   <span >{language.kullanim_kosullari_kabul}</span> 
                  </a>
              </div>
              <div className="mb-4">
                  <a className="d-flex align-items-center mb-3 form-control"  onClick={()=>{setbilgipaylasim(bilgipaylasim==false)}}>
                    <div style={{width: '40px', height: '25px'}}>
                    <input type="checkbox" style={{
                      width: 25,
                      height: 25,
                      marginRight: 15,
                      marginBottom: 2,
                    }} checked={bilgipaylasim} onClick={() => setbilgipaylasim(bilgipaylasim==false)} />
                    </div>
                    <span >{language.kullanim_kosullari_bilgi_paylaşım.replace("${title}",serverReducer.title)}</span>
                  </a>
              </div>
    {/* <a  className="btn btn-resto btn-block mt-5">Login</a> */}
   
  {/* <span className="text-already">Don't have an account? <a>Sign Up</a></span> */}
  {/* <div className="rp-login-social">
    <div className="head"><span className="title">Or sign in with social account</span></div>
    <ul className="list-unstyled">
      <li><a ><i className="fab fa-google" /></a></li>
      <li><a ><i className="fab fa-twitter" /></a></li>
      <li><a ><i className="fab fa-instagram" /></a></li>
    </ul>
  </div> */}
</div>

        </div>
      </div>

      <div className="rp-action rp-action-cart">
       
        <button
          className="btn btn-dark btn-block"
          style={{borderColor:serverReducer.buttonColor,color:"white"}} disabled={loading}
           onClick={gonder}
          
        >
          {loading ? (
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            language.telefon_onay_gonder
          )}
        </button>
      </div>
    </div>
  );
}
